import cn from "classnames"
import { Formik } from "formik"
import { find as _find, isArray as _isArray } from "lodash"
import React, { Component } from 'react'
import Button from '../components/button'
import Input from '../components/input'
import PageContents from '../components/pageContents'
import Select from '../components/select'
import SEO from "../components/seo"
import Title from '../components/title'
import NewLayout from '../layout/newLayout'
import { sendContact } from "../services/contact"
import styles from '../styles/scss/pages/industrialSampleRequestForm.module.scss'

export default class Index extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			submitted: false,
			selectedIndustry: null,
			selectedNumberOfEmployees: null,
		  	isMobile: false,
		};
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		// Initial check to set the correct value of isMobile
		this.handleResize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize() {
		if (window.innerWidth <= 576) {
			this.setState({ isMobile: true });
		} else {
			this.setState({ isMobile: false });
		}
	}
	
	industries = [{
		value: 'food-beverage',
		text: 'Food & Beverage'
	}, {
		value: 'oil-gas-chemical',
		text: 'Oil / Gas / Chemical'
	}, {
		value: 'mining-aggregate',
		text: 'Mining / Aggregate'
	}, {
		value: 'forest-paper',
		text: 'Forest / Paper'
	}, {
		value: 'power-utilities',
		text: 'Power & Utilities'
	}, {
		value: 'pharmaceutical',
		text: 'Pharmaceutical'
	}, {
		value: 'government',
		text: 'Government'
	}, {
		value: 'transportation-vehicles',
		text: 'Transportation / Vehicles'
	}, {
		value: 'warehouse-logistics',
		text: 'Warehouse & Logistics'
	}, {
		value: 'metal-metal-fabrication',
		text: 'Metal / Metal Fabrication'
	}, {
		value: 'commercial-construction',
		text: 'Commercial/Construction'
	}, {
		value: 'other',
		text: 'OTHER'
	}]

	numberOfEmployees = [{
		value: '1 to 9',
		text: '1 to 9'
	}, {
		value: '10 to 49',
		text: '10 to 49'
	}, {
		value: '50 to 99',
		text: '50 to 99'
	}, {
		value: '100 to 499',
		text: '100 to 499'
	}, {
		value: 'Over 500',
		text: '500+'
	}]

	render() {
		const pg = this.props.pageContext.page
		const attached = pg.attachments.filter((att) => {
			return att.slug === 'thank-you-contact'
		})
		var thankyou = null
		if (attached.length > 0) {
			thankyou = attached[0]
		} else {
			throw new Error("thank-you-contact is not attached")
		}
		const heroSectionContent = {
			title: pg.content[0].title,
			image: pg.content[0].image,
		}

		return (
			<NewLayout>
				{pg.seo.title && <h1 style={{ display: 'none' }}>{pg.seo.title}</h1>}
				<SEO page={pg} />
				<div className='d-flex flex-column flex-fill'>
					<div
						className={styles.hero}
						style={{
							backgroundImage: `url(${
								this.state.isMobile
									? "/img/industrial-page/industrial-sample-request-form-hero-mobile.png"
									: heroSectionContent.image.url
							})`,
						}}
					>
						<h2 className={styles.heroTitle}>
							{heroSectionContent.title}
						</h2>
					</div>
					<div className={styles.container}>
						<>
							<div className={cn(styles.formItems, "row")}>
								<div className={styles.formContainer}>
									{
										this.state.submitted ?
										<PageContents content={thankyou.content} />
										:
										<>
											<div className={styles.info}>
												<h3 className={styles.infoTitle}>
													personal information
												</h3>
												<span className={styles.infoDescription}>
													* Required Fields
												</span>
											</div>
											<Formik
												initialValues={{
													first_name: "",
													last_ame: "",
													job_title: "",
													company: "",
													business_phone: "",
													business_email: "",
													business_address: "",
													zip_code: "",
													industry: "",
													num_employees: "",
													message: "",
													preferred_distributors: ""
												}}
												validate={values => {
													var errors = []
													//
													if (values.first_name === "") {
														errors.push({ field: "first_name", message: "First Name is required" })
													}
													//
													if (values.last_name === "") {
														errors.push({ field: "last_name", message: "Last Name is required" })
													}
													//
													if (values.job_title === "") {
														errors.push({ field: "job_title", message: "Job Title is required" })
													}
													//
													if (values.company === "") {
														errors.push({ field: "company", message: "Company is required" })
													}
													if (values.business_email === "") {
														errors.push({ field: "business_email", message: "Business Email is required" })
													} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.business_email)) {
														errors.push({ field: "business_email", message: "Invalid email address" })
													}
													//
													if (values.business_phone === "") {
														errors.push({
															field: "business_phone",
															message: "Business Phone Number is required",
														})
													}
													//
													if (values.business_address === "") {
														errors.push({ field: "business_address", message: "Business Address is required" })
													}
													//
													if (values.zip_code === "") {
														errors.push({ field: "zip_code", message: "ZIP Code is required" })
													}
													//
													if (values.industry === null) {
														errors.push({ field: "industry", message: "Please select a Industry" })
													}
													//
													if (values.num_employees === null) {
														errors.push({ field: "num_employees", message: "Please select a Number of Employees" })
													}
													//
													if (values.preferred_distributors === null || values.preferred_distributors === "") {
														errors.push({ field: "preferred_distributors", message: "Please enter Preferred distributors" })
													}

													return errors
												}}

												onSubmit={(values, { setSubmitting }) => {

													this.setState({
														showModal: true
													})
													sendContact(values, 'sample-request').then(() => {
														setSubmitting(false)
														this.setState({
															submitted: true,
															showModal: false
														}, () => {
														})
													}).catch((err) => {
														console.log('ERROR!')
														console.log(err)
														setSubmitting(false)
														this.setState({
															showModal: false
														})
													})
												}}
											>
												{(props) => (
													<form className={styles.form} onSubmit={props.handleSubmit}>
														<div>
															<div className={styles.row}>
																<Input
																	name="first_name"
																	title="First Name*"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.first_name}
																/>
																<Input
																	name="last_name"
																	title="Last Name*"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.last_name}
																/>
															</div>
															<div className={styles.row}>
																<Input
																	name="job_title"
																	title="Job Title*"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.job_title}
																/>
																<Input
																	name="company"
																	title="Company*"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.company}
																/>
															</div>
															<div className={styles.row}>
																<Input
																	name="business_phone"
																	title="Business Phone*"
																	phone="true"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.business_phone}
																/>
																<Input
																	name="business_email"
																	title="Business Email*"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.business_email}
																/>
															</div>
															<div className={styles.row}>
																<Input
																	name="business_address"
																	title='Business Address*'
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.business_address}
																/>
																<Input
																	name="zip_code"
																	title='Zip Code*'
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.zip_code}
																/>																
															</div>
															<div className={styles.row} style={{ gap: '4%' }}>
																<div className={styles.industry}>
																	<Select
																		title="Industry*"
																		titleClassName={styles.selectTitleNew}
																		noMargin
																		className={styles.selectNew}
																		placeholder='Select An Industry'
																		values={this.industries}
																		onBlur={props.handleBlur}
																		value={props.values.industry}
																		onChange={(e) => {
																			this.setState({
																				selectedIndustry: _find(this.industries, { value: e.value }),
																			}, () => {
																				props.setFieldValue('industry', e.value)
																			})
																		}}
																	/>
																</div>
																<div className={styles.industry}>
																	<Select
																		title="# OF EMPLOYEES WHO REQUIRE WD-40*"
																		titleClassName={styles.selectTitleNew}
																		noMargin
																		className={styles.selectNew}
																		placeholder='Select Number Of Employees'
																		values={this.numberOfEmployees}
																		onBlur={props.handleBlur}
																		value={props.values.num_employees}
																		onChange={(e) => {
																			this.setState({
																				selectedNumberOfEmployees: _find(this.numberOfEmployees, { value: e.value }),
																			}, () => {
																				props.setFieldValue('num_employees', e.value)
																			})
																		}}
																	/>
																</div>
															</div>
															<div className={styles.row}>
																<Input
																	name="preferred_distributors"
																	title="Who are your preferred distributors?*"
																	className={styles.textarea}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.preferred_distributors}
																/>
															</div>
															<div className={styles.feedback}>
																<ul>
																	{_isArray(props.errors) && props.errors.filter((f) => { return props.touched[f.field] }).map((e) => {
																		return <li key={e.field}>{e.message}</li>
																	})}
																</ul>
															</div>
															<div className={styles.row}>
																<Button
																	text="Submit"
																	newBlue
																	submit
																	className={styles.input}
																/>
															</div>
														</div>															
													</form>
												)}
											</Formik>
										</>
									}
								</div>
							</div>
						</>
					</div>
				</div>
				{this.state.showModal &&
					<div className={cn(styles.customModal)}>
						<div className={cn(styles.modalContent)}>
							<div>
								<i className={cn('fa', 'fa-clock-o')}></i>
							</div>
							<div>
								<Title yellow>Please wait...</Title>
							</div>
						</div>
					</div>
				}
			</NewLayout>
		);
	}
}